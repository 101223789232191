html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body{
    margin: 0;
}
p, label{
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
}
input[type="text"], input[type="email"], textarea{
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
}
h1, h2, h3, h4{
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}
strong{
  font-weight: 600;
}