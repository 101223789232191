@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

@import 'variables';
@import 'mixins';
@import 'global';
@import 'layout';
@import 'ui';

//removes the small gap at bottom of the slick-slider
.slick-slider{
    line-height: 0;
}