.c_header{
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 190px;
    background: rgb(10,9,8);
    background: linear-gradient(180deg, rgba(10,9,8,1) 0%, rgba(10,9,8,0) 100%);
    @include transition(0.5s); 
    top: 0;
    .c_logo{
        position: absolute;
        left: 50%;
        top:50%;
        transform: translate(-50%, -50%);
    }
    .c_hamburger-icon{
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-53%);
        @media(min-width: $laptop){
            display: none;
        }
    }
    svg{
        width: 116px;
        @include transition(0.5s);
    }
    &.c_transformed{
        background: #fff;
        height: 80px;
        @media(min-width: $laptop){
            height: 100px;
        }
        .c_logo{
            top: 52%;
        }
        svg{
            path{
                fill: $dark;
            }
            width: 65px;
            height: 65px;
            @media(min-width: $laptop){
                width: 75px;
                height: 75px;
            }
        }
        .c_hamburger-icon{            
            // border: 1px dashed $dark;   
            width: 35px;
            height: 35px;         
            span{
                background-color: $dark;
                &:nth-of-type(1){
                    top: 19px;
                }
                &:nth-of-type(2){
                    bottom: 19px;
                }
            }
            &.c_active{
                span{
                    &:nth-of-type(1){
                        top: 50%;
                    }
                    &:nth-of-type(2){
                        bottom: 50%;
                    }
                }
            }
        }
        .c_widescreen-menu .c_contacts-list a{
            color: $dark;
        }
    }
    
}

.c_header-v2{
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 190px;
    @include transition(0.5s); 
    top: 0;
    .c_logo{
        position: absolute;
        left: 50%;
        top:50%;
        transform: translate(-50%, -50%);
    }
    .c_hamburger-icon{
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-53%);
        @media(min-width: $laptop){
            display: none;
        }
    }
    background: #fff;
    svg{
        @include transition(0.5s);
        path{
            fill: $dark;
        }
    }
    .c_hamburger-icon{            
        border: 1px dashed $dark;            
        span{
            background-color: $dark;
        }
    }
    .c_widescreen-menu .c_contacts-list a{
        color: $dark;
    }
    &.c_transformed{
        background: #fff;
        height: 100px;
        svg{
            path{
                fill: $dark;
            }
            width: 50px;
            height: 50px;
            @media(min-width: $laptop){
                width: 70px;
                height: 70px;
            }
        }
        .c_hamburger-icon{            
            // border: 1px dashed $dark;   
            width: 35px;
            height: 35px;         
            span{
                background-color: $dark;
                &:nth-of-type(1){
                    top: 19px;
                }
                &:nth-of-type(2){
                    bottom: 19px;
                }
            }
            &.c_active{
                span{
                    &:nth-of-type(1){
                        top: 50%;
                    }
                    &:nth-of-type(2){
                        bottom: 50%;
                    }
                }
            }
        }
        .c_widescreen-menu .c_contacts-list a{
            color: $dark;
        }
    }
}

.c_mobile-menu{
    position: fixed;
    width: 100%;
    height: 100vh;
    min-height: 600px;
    top: 0;
    left:100%;
    background-color: #fff;
    z-index: -1;
    @include transition(0.5s);
    @media(min-width: $laptop){
        display: none;
    }
    &.c_open{
        left:0;
    }
    .c_contacts-list{
        position: absolute;
        bottom: 30px;
        list-style: none;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 16px;
        display: flex;
        justify-content: center;
        width: 100%;
        flex-wrap: wrap;
        padding-left: 0;
        a{
            color: $dark;
            text-decoration: none;
            display: block;
            padding: 10px;
        }
    }
}



.c_mobile-nav{
    width: 100%;
    margin: 0;
    padding-left: 0;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    li{
        list-style: none;
        font-family: 'Montserrat', sans-serif;
        a{
            text-decoration: none;
            font-size: 20px;
            color: $dark;
            display: block;
            padding: 10px 0;
            font-weight: 600;
        }
    }
}

.c_widescreen-menu{
    display:none;
    @media(min-width:$laptop){
        display:block;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        @media(min-width: $desktop){
            right: 100px;
        }        
    }
    .c_contacts-list{
        display:flex;
        justify-content: flex-end;
        padding-left: 0;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 16px;
        li{
            list-style: none;
        }
        a{
            display: block;
            padding: 10px 0 10px 24px;
            text-decoration: none;
            color: #fff;
            @include transition(0.5s);
        }
    }
}

.c_footer{
    background-color: #343A40;
    padding: 30px;
    position: relative;
    @media(min-width: $desktop){
        padding: 100px;
    }
    a{
        color: #fff;
        text-decoration: none;
    }
    .c_inner{
        .c_column{
            padding: 15px 0;
        }
        .c_column_1{
            text-align: center;
            .c_logo{
                width: 120px;
                max-width: 100%;
            }
        }
        @media(min-width: $tablet){
            display: flex;
            justify-content: flex-start;
            margin-left: -30px;
            margin-right: -30px;
            .c_column{
                padding: 30px;
                p{
                    max-width: 500px;
                }
            }
            .c_column_1{
                width: 20%;
                text-align: left;
                .c_logo{
                    width: 170px;
                }
            }
            .c_column_2{
                width: 40%;
            }
            .c_column_3{
                width: 40%;
            }
        }
    }
}

.c_section{
    
    .c_button{
        margin: 10px 0 0 0;
    }
    .c_section-heading{
        &:before{
            content: "";
            width: 100px;
            height: 3px;
            background-color: $lightGrey;
            display: block;
            margin: 0 auto;
            position: relative;
            top: -20px;
        }
    }
}

.c_container{
    @media(min-width: $laptop){
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        // margin-bottom: -4px;
    }
    
    .c_order-1{
        @media(min-width: $laptop){
            order: -1;
        }
    }
}

.c_light-background{
    background-color: $lightBackground;
}

.c_grid{
    text-align: center;
    padding: 60px 0;
    @media(min-width: $desktop){
        padding: 100px 0;
    }
    // &.c_light-background{
    //     background-color: $lightBackground;
    // }
    p{
        color: $dark;
        max-width: 770px;
        margin: 0 auto 26px auto;
    }
    &.c_grid-6{
        @media(min-width: $laptop){
            width: 50%;
            padding: 0 0 47% 0;
            position: relative;
            .c_inner{
                position: absolute;
                top: 50%;
                left: 0;
                transform:translateY(-50%);
                .c_copy{
                    max-height: 320px;
                    overflow: auto;
                    padding: 0 10px;
                    p{
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
            
        }
    }
    .c_inner{
        padding: 0 30px;
        @media(min-width: $desktop){
            padding: 0 100px;
            top: 50%;
        }
    }
}

.c_grid-slider{
    @media(min-width: $laptop){
        width: 50%;
        img{
            width: 100%;
        }
    }
    img{
        width: 100%;
    }
    .slick-slide{
        padding: 60px 10px 0 10px;
        @media(min-width: $laptop){
            padding: 0;
        }
    }
}

.slick-arrow{
    position: absolute;
    bottom: 30px;
    z-index: 1;
    &.slick-prev{
        left: calc(50% - 25px);
        top: unset;
    }
    &.slick-next{
        right: calc(50% - 29px);
        top: unset;
    }
}

.c_top-section{
    margin-top: 200px;
}

.c_hero-banner-slider{
    position: relative;
    .c_scroll-down-button{
        position: absolute;
        top: 42.5%;
        right: 15px;
        @media(min-width: 459px){
            right: 8px;
        }
        @media(min-width: $desktop){
            right: 80px;
        }
    }
}

.c_scroll-to-top-button{
    position: absolute;
    bottom: 30px;
    right: 30px;
    @media(min-width: $desktop){
        right: 100px;
        bottom: 100px;
    }
}