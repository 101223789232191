$dark: #495057;
$black: #0A0908;
$darkBackground: #343A40;
$overlay: rgba(49, 49, 49, 0.85);
$lightGrey:#ADB5BD;
$lightBackground: #E9ECEF;
$red: #bd1010;

$tablet: 768px;
$laptop: 1024px;
$desktop: 1200px;