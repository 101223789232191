.c_hero-banner-copy{
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    @media(min-width: $tablet){
        font-size: 22px;
        line-height: 32px;
    }
    @media(min-width: $laptop){
        font-size: 24px;
        line-height: 34px;
    }
    @media(min-width: $desktop){
        font-size: 26px;
        line-height: 36px;
    }
}

.c_section-heading{
    font-size: 28px;
    color: $dark;
    @media(min-width: $tablet){
        font-size: 32px;
    }
    @media(min-width: $laptop){
        font-size: 36px;
    }
    @media(min-width: $desktop){
        font-size: 40px;
    }
}

.c_section-heading_2{
    font-size: 28px;
    color: $dark;
    margin-top: 0;
    @media(min-width: $tablet){
        font-size: 32px;
    }
    @media(min-width: $laptop){
        font-size: 36px;
    }
    @media(min-width: $desktop){
        font-size: 40px;
    }
}

.c_footer-heading{
    color: #fff;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 33px;
    &:after{
        content:"";
        display: block;
        width: 50px;
        height: 3px;
        background-color: $lightGrey;
        position: relative;
        top: 10px;
    }
}

.c_footer-par{
    color: #fff;
    font-size: 16px;
    line-height: 26px;
}

.c_button{
    font-family: 'Montserrat', sans-serif;
    color: $black;
    font-size: 14px;
    text-decoration: none;
    border: 1px solid $black;
    border-radius: 0;
    background-color: #fff;
    display:inline-block;
    padding: 10px 24px;
    cursor: pointer;
    @include transition(0.15s);
    &:hover{
        background-color: $darkBackground;
        color: #fff;
    }
}

.c_hero-banner-slider{
    position: relative;
    height: 100vh;
    background-color: $darkBackground;
    .slick-slider{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .slick-arrow{
        @media(min-width: $laptop){
            &.slick-next{
                right: 30px;
            }
            &.slick-prev{
                right: 60px;
                left: unset;
            }
        }
        
        @media(min-width: $desktop){
            &.slick-next{
                right: 100px;
            }
            &.slick-prev{
                right: 130px;
            }
        }
    }
}

.c_widescreen-nav{
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0;
    display: none;
    @media(min-width: $laptop){
        display: block;
    }
    ul{
        padding-left: 0;
        display: flex;
        justify-content: center;
        margin: 16px 0;
        li{
            list-style: none;
            a{
                text-decoration: none;
                color: #fff;
                font-family: 'Montserrat', sans-serif;
                padding: 12px 20px;
                display: block;
                &:after{
                    display: block;
                    content: "";
                    width: 60px;
                    height: 3px;
                    background-color: #fff;
                    margin: 10px auto 0 auto;
                    @include transition(0.15s);
                    visibility: hidden;
                    opacity: 0;
                }
                &:hover{
                    &:after{
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.c_hero-banner-slide{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    min-height: 500px;
    width: 100vw;
    position: relative;
    .c_inner{
        position: absolute;
        width: calc(100% - 60px);
        left: 50%;
        top: 50%;
        transform:translate(-50%, -50%);
        @media(min-width: $desktop){
            width: calc(100% - 200px);
        }
    }

    .overlay-copy-panel{
        background-color: $overlay;
        padding: 30px;
        max-width: 400px;
        @media(min-width: $tablet){
            max-width: 60%;
        }
        @media(min-width: $laptop){
            padding: 60px;
        }
        @media(min-width: $desktop){
            width: 50%;
            max-width: 600px;
        }
        .c_text{
            margin: 0;
        }
    }
}

.c_hero-banner-nav{
    display: none!important;
    justify-content: center;
    margin: 0;
    padding-left: 0;
    position: absolute;
    bottom: 40px;
    width: 100%;
    @media(min-width: $tablet){
        display: flex!important;
    }
    li{
        list-style: none;
        cursor: pointer;
        .c_item{
            color: #fff;
            font-family: 'Montserrat', sans-serif;
            padding: 12px 20px;
        }
        &.slick-active{           
            &:after{
                display: block;
                content: "";
                width: 60px;
                height: 3px;
                background-color: #fff;
                margin: 0 auto;
            }
        }
    }
}

.c_hamburger-icon{
    width: 45px;
    height: 45px;
    position: relative;
    background-color: transparent;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    @include transition(0.5s);
    span{
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #fff;
        border-radius: 100px;
        @include transition(0.5s);
        &:nth-of-type(1){
            left: 0;
            top: 17px;
        }
        &:nth-of-type(2){
            left: 0;
            bottom: 17px;
        }
    }
    &.c_active{
        span{
            background-color: $dark;
            &:nth-of-type(1){
                top: 50%;
                transform: translateY(-50%) rotate(-225deg);
            }
            &:nth-of-type(2){
                // bottom: unset;
                bottom: 50%;
                transform: translateY(-50%) rotate(-135deg);
            }
        }
    }
}

.c_logo{
    svg{
        path{
            @include transition(0.5s);
        }
    }
    &.invert{
        svg{
            path{
                fill: $dark;
            }
        }
    }
}

.c_horline{
    width: 100px;
    background-color: $lightGrey;
    height: 3px;
    margin: 30px auto 0 auto;
    
}

.slick-prev{
    span{
        border-left: 3px solid #fff;
        border-top: 3px solid #fff;
        transform: rotate(-45deg);
        width: 15px;
        height: 15px;
        display: block;
    }
    &:before{
        display:none;
    }
}

.slick-next{
   span{
        border-right: 3px solid #fff;
        border-top: 3px solid #fff;
        transform: rotate(45deg);
        width: 15px;
        height: 15px;
        display: block;
    }
    &:before{
        display:none;
    }
}

.c_form{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 50px auto 0 auto;
    width: 100%;
    max-width: 600px;
    .c_form-group{
        text-align: left;        
        margin-bottom: 15px;
        @media(min-width: 600px){
            padding: 0 15px;
        }
        label{
            display: block;
            margin-bottom: 5px;
            width: 100%;
        }
        .c_form-control{
            display: block;
            width: 100%;
            border: 1px solid $black;
            border-radius: 0;
            background-color: #fff;
            padding: 7px 12px;
        }
    } 
    .c_form-group-12{
        width: 100%;
    }
    .c_form-group-6{
        width: 100%;
        @media(min-width: 600px){
            width: 50%;
        }
    }
    .c_button{
        margin: 10px 0;
        @media(min-width: 600px){
            margin: 10px 15px;
        }
    }
}

.c_error{
    .c_form-control{
        border: 1px solid $red!important;
    }
}

.c_error-message{
    color: $red;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    margin-top: 5px;
}

p.c_error-message{
    color: $red;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    margin-top: 5px;
    width: 100%;
}

p.c_message-sending-message{
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    margin-top: 5px;
}

.c_scroll-down-button{
    display:none;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 26px;
    transform: rotate(90deg) translateY(-50%);
    text-decoration: none;
    &:after{
        width: 65px;
        height: 1px;
        background-color: #fff;
        content: "";
        display: block;
        position: relative;
        left: 93px;
        bottom: 14px;
    }
    @media(min-width: $laptop){
        display:block;
    }
}

.c_scroll-to-top-button{
    width: 40px;
    height: 40px;
    border: 1px solid #fff;
    border-radius: 100px;
    display: block;
    &:after{
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        border-left: 1px solid #fff;
        border-top: 1px solid #fff;
        transform: rotate(45deg);
        margin:14px 12px;        
    }
}